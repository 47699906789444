import { Container } from 'react-bootstrap'

function NotFound() {
  return (
    <Container className='pt-4'>
      <h1>404 Not Found</h1>
    </Container>
  )
}

export default NotFound
